"use client";

import clsx from "clsx";
import { LoaderIcon, MinusIcon, PlusIcon } from "lucide-react";
import { useFormState, useFormStatus } from "react-dom";
import { updateItemQuantity } from "./actions";
import { useEffect, useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { useDebouncedCallback } from "use-debounce";

function SubmitButton({
  type,
  disabled
}: {
  type: "plus" | "minus";
  disabled?: boolean | false;
}) {
  const { pending } = useFormStatus();

  return (
    <button
      type="submit"
      disabled={disabled}
      onClick={(e: React.FormEvent<HTMLButtonElement>) => {
        if (pending) e.preventDefault();
      }}
      aria-label={
        type === "plus" ? "Increase item quantity" : "Reduce item quantity"
      }
      aria-disabled={pending || disabled}
      className={clsx(
        "ease flex h-full min-w-[36px] max-w-[36px] flex-none items-center justify-center rounded-full px-2 transition-all duration-200 hover:border-neutral-800 hover:opacity-80",
        {
          "cursor-not-allowed": pending || disabled,
          "ml-auto": type === "minus"
        }
      )}
    >
      {pending ? (
        <LoaderIcon className="bg-transparent animate-spin opacity-20 " />
      ) : type === "plus" ? (
        <PlusIcon
          className={clsx(disabled ? "h-4 w-4 opacity-50" : "h-4 w-4")}
        />
      ) : (
        <MinusIcon
          className={clsx(disabled ? "h-4 w-4 opacity-50" : "h-4 w-4")}
        />
      )}
    </button>
  );
}

export function CompoEditItemQuantityButton({
  item,
  type
}: {
  item: variantKeyField;
  type: "plus" | "minus";
}) {
  const [message, formAction] = useFormState(updateItemQuantity, null);
  const actionWithVariant = formAction.bind(null, {
    ...item,
    quantity: type == "plus" ? 1 : -1,
    isInputQuantity: false
  });

  const { toast } = useToast();

  useEffect(() => {
    if (!message) return;
    if (message?.toString()?.length > 0)
      toast({
        description: message,
        variant: "destructive"
      });
  }, [message, toast]);

  return (
    <form action={actionWithVariant}>
      <SubmitButton
        type={type}
        disabled={
          type == "plus" ? item.jumlah >= (item.detail?.stock || 0) : false
        }
      />
      <p aria-live="polite" className="sr-only" role="status">
        {message}
      </p>
    </form>
  );
}

export function EditItemQuantityButton({ item }: { item: variantKeyField }) {
  const [message, formAction] = useFormState(updateItemQuantity, null);
  const [qty, setQty] = useState(item.jumlah);

  const { toast } = useToast();
  const debounced = useDebouncedCallback(
    // function
    (value) => {
      if (value >= (item.detail?.stock || 0) && item.jumlah == item.detail?.stock) return;

      const actionWithVariantInput = formAction.bind(null, {
        ...item,
        quantity: value,
        isInputQuantity: true
      });
      value > 0 &&
        value <= (item.detail?.stock || 0) &&
        actionWithVariantInput();
    },
    // delay in ms
    500
  );

  useEffect(() => {
    if (item?.jumlah) setQty(Number(item?.jumlah));
  }, [item?.jumlah]);

  useEffect(() => {
    if (!message) return;
    if (message?.toString()?.length > 0)
      toast({
        description: message,
        variant: "destructive"
      });
  }, [message, toast]);

  return (
    <div className="ml-auto flex h-9 flex-row items-center rounded-full border border-neutral-200 dark:border-neutral-700">
      <CompoEditItemQuantityButton item={{ ...item }} type="minus" />
      <input
        className={"outline-none bg-transparent text-center w-[5ch]"}
        placeholder="0"
        value={item?.jumlah <= -1 ? 0 : qty}
        disabled={item?.jumlah <= 0}
        onChange={(val) => {
          const re = /^[0-9\b]+$/;
          if (val.target.value === "" || re.test(val.target.value)) {
            const value =
              Number(val?.target?.value) < -1
                ? 0
                : Number(val?.target?.value) > (item.detail?.stock || 0)
                  ? item.detail?.stock
                  : val?.target?.value;

            setQty(Number(value));
            debounced(Number(value));
          }
        }}
      />
      <CompoEditItemQuantityButton item={{ ...item }} type="plus"  />
    </div>
  );
}
