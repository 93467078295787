import { useToast } from "@/components/ui/use-toast";
import { getProduct } from "@/functions/data";
import React, { useEffect, useState } from "react";
import { DeleteItemButton } from "./delete-item-button";
import Link from "next/link";
import Image from "next/image";
import clsx from "clsx";
import { checkThumbanil, isThumbanil } from "../product/Product";
import currency from "@/functions/currency";
import { EditItemQuantityButton } from "./edit-item-quantity-button";
import { useHitungDiskon } from "@/lib/utils";
import HargaItemCart from "@/components/atoms/harga-item-cart";

export default function ListProductCart({
  setIsOpen,
  item,
  isVariantId
}: {
  setIsOpen: (a: boolean) => void;
  item: variantKeyField;
  isVariantId: string;
}) {
  const { toast } = useToast();
  const [variantDetail, setVariantDetail] = useState<VariantDiskon>();

  async function getDetailProduct(id: string) {
    try {
      const data: Product = await getProduct({
        id,
        methodNextFetch: { tags: ["cart-detail-product"] }
      });
      if (!data.product_id) throw new Error("detail variant not exist");

      setVariantDetail({
        ...data,
        ...data?.variants?.filter((item) => item?.sku == isVariantId)?.[0]
      });
    } catch (error) {
      error instanceof Error &&
        toast({
          description: error.message,
          variant: "destructive"
        });
    }
  }

  useEffect(() => {
    if (item.product) getDetailProduct(item.product);
  }, [item.product]);

  return (
    <li className="flex w-full flex-col border-b border-neutral-300 dark:border-neutral-700">
      <div className="relative flex w-full flex-row justify-between px-1 py-4">
        <div className="absolute z-40 -mt-2 ml-[55px]">
          <DeleteItemButton item={{ ...item, detail: variantDetail }} />
        </div>
        <Link
          href={`/product/${item.nama.replaceAll(" ", "+")}-${variantDetail?.product_id}`}
          onClick={() => setIsOpen(false)}
          className="z-30 flex flex-row space-x-4"
        >
          <div className="relative h-16 w-16 cursor-pointer justify-center items-center flex overflow-hidden rounded-md border border-neutral-300 bg-neutral-300 dark:border-neutral-700 dark:bg-neutral-900 dark:hover:bg-neutral-800">
            <Image
              className={clsx({
                "object-cover h-full w-full": isThumbanil(
                  variantDetail?.thumbnails || ""
                ),
                "object-cover bg-cover h-6 w-6": !isThumbanil(
                  variantDetail?.thumbnails || ""
                )
              })}
              width={64}
              height={64}
              alt={variantDetail?.sku as string}
              src={checkThumbanil(variantDetail?.thumbnails || "") as string}
            />
          </div>

          <div className="flex flex-1 flex-col text-base gap-1">
            <span className="leading-tight">{item.nama}</span>
            {variantDetail?.variant1_size || variantDetail?.variant2_color ? (
              <p className="text-xs text-neutral-500 dark:text-neutral-400">
                {variantDetail?.variant1_size}, {variantDetail?.variant2_color}
              </p>
            ) : null}
          </div>
        </Link>
        <div className="flex h-16 flex-col justify-between">
          {!variantDetail ? null : (
            <HargaItemCart item={item} variantDetail={variantDetail} />
          )}
          <EditItemQuantityButton item={{ ...item, detail: variantDetail }} />
        </div>
      </div>
    </li>
  );
}
