"use client";

import clsx from "clsx";
import { LoaderIcon, XIcon } from "lucide-react";
import { useFormState, useFormStatus } from "react-dom";
import { removeItem } from "./actions";
import { useEffect } from "react";
import { useToast } from "@/components/ui/use-toast";

function SubmitButton() {
  const { pending } = useFormStatus();

  return (
    <button
      type="submit"
      onClick={(e: React.FormEvent<HTMLButtonElement>) => {
        if (pending) e.preventDefault();
      }}
      aria-label="Remove cart item"
      aria-disabled={pending}
      className={clsx(
        "ease flex h-[17px] w-[17px] items-center justify-center rounded-full bg-greyscaleGON-500 transition-all duration-200",
        {
          "cursor-not-allowed px-0": pending
        }
      )}
    >
      {pending ? (
        <LoaderIcon
          size={14}
          className="text-white animate-spin bg-transparent"
        />
      ) : (
        <XIcon size={14} className="hover:text-accent-3 text-white" />
      )}
    </button>
  );
}

export function DeleteItemButton({ item }: { item: variantKeyField }) {
  const [message, formAction] = useFormState(removeItem, null);
  const selectedCart = item || "";
  const actionWithVariant = formAction.bind(null, selectedCart);
  const { toast } = useToast();

  useEffect(() => {
    if (!message) return;
    if (message?.toString()?.length > 0)
      toast({
        description: message,
        variant: "destructive"
      });
  }, [message, toast]);

  return (
    <form action={actionWithVariant}>
      <SubmitButton />
      <p aria-live="polite" className="sr-only" role="status">
        {message}
      </p>
    </form>
  );
}
