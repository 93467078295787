"use client";

import {
  ShoppingBasketIcon,
  ShoppingCart,
  ShoppingCartIcon,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from "@/components/atoms/button";
import currency from "@/functions/currency";
import { deleteCostShipping } from "../checkout/action";
import ListProductCart from "./list-product";

export default function CartModal({ cart }: { cart: cartField }) {
  const reduceSizeCart = Object.entries(cart?.products ?? {}).reduce(
    (accumulator, [_, item]) => accumulator + (item as variantKeyField).jumlah,
    0
  );

  const [isOpen, setIsOpen] = useState(false);
  const quantityRef = useRef(reduceSizeCart);

  useEffect(() => {
    // Open cart modal when quantity changes.
    if (reduceSizeCart !== quantityRef.current) {
      // But only if it's not already open (quantity also changes when editing items in cart).
      if (!isOpen) {
        setIsOpen(true);
      }

      if (reduceSizeCart <= 0) {
        deleteCostShipping();
        localStorage.removeItem("shipping");
        localStorage.removeItem("informasi");
        localStorage.removeItem("choosePayment");
        localStorage.removeItem("saveHistory");
      }
      // Always update the quantity reference
      quantityRef.current = reduceSizeCart;
    }
  }, [isOpen, reduceSizeCart, quantityRef]);

  const isSizeCart = reduceSizeCart > 0;

  return (
    <div className="@container-normal">
      <Sheet
        open={isOpen}
        onOpenChange={(a) => {
          setIsOpen(a);
        }}
      >
        <SheetTrigger className="relative group px-3 py-2 h-10 ring-1 ring-greyscaleGON-400 rounded-full">
          <ShoppingCart className="w-4 h-4 group-hover:scale-150 transition-all delay-75 duration-75 text-greyscaleGON-400 group-hover:text-primaryGON-300" />
          {isSizeCart ? (
            <p className="absolute font-poppins right-0 top-0 -mr-1 -mt-1 h-4 min-w-4 rounded-full bg-secondaryGON-400 text-[11px] font-medium text-white">
              {reduceSizeCart > 99 ? "99+" : reduceSizeCart}
            </p>
          ) : null}
        </SheetTrigger>
        <SheetContent>
          <SheetTitle className="text-2xl">My Cart</SheetTitle>
          <SheetDescription />
          {!cart || cart?.products?.length === 0 ? (
            <div className="mt-20 flex w-full flex-col items-center justify-center overflow-hidden">
              <ShoppingBasketIcon className="@5xl:w-12 @5xl:h-12 w-8 h-8" />
              <p className="mt-6 text-center text-2xl font-semibold">
                Your cart is empty.
              </p>
            </div>
          ) : (
            <div className="flex h-[95%] flex-col justify-between overflow-hidden p-1">
              <ul className="flex-grow overflow-auto py-4">
                {Object.entries<variantKeyField>(cart.products ?? {}).map(
                  ([key, item], i) => {
                    let isVariantId = key?.split("_")?.[1];
                    return (
                      <ListProductCart
                        item={item}
                        key={i}
                        setIsOpen={setIsOpen}
                        isVariantId={isVariantId}
                      />
                    );
                  }
                )}
              </ul>

              <div className="py-4 text-sm text-greyscaleGON-700 dark:text-greyscaleGON-400">
                <div className="mb-3 flex items-center justify-between border-b border-neutral-200 pb-1 dark:border-neutral-700">
                  <p>Weight</p>
                  <p className="text-base font-poppins">
                    {!cart?.weight ? 0 : cart?.weight} gr
                  </p>
                </div>
                <div className="mb-3 flex items-center justify-between border-b border-neutral-200 pb-1 pt-1 dark:border-neutral-700">
                  <p>Shipping</p>
                  <p className="text-right font-poppins">
                    Calculated at checkout
                  </p>
                </div>
                <div className="mb-3 flex items-center justify-between border-b border-neutral-200 pb-1 pt-1 dark:border-neutral-700">
                  <p>Total</p>
                  {cart?.nilai_voucher ? (
                    <div className="flex flex-col-reverse items-end">
                      <p className="text-base font-poppins text-greyscaleGON-400">
                        {currency((cart?.total_pembayaran - (!cart.biaya_pengiriman ? 0 : cart.biaya_pengiriman)) - cart?.nilai_voucher)}
                      </p>
                      <p className="text-base font-poppins text-greyscaleGON-400 line-through">
                        {currency((cart?.total_pembayaran - (!cart.biaya_pengiriman ? 0 : cart.biaya_pengiriman)))}
                      </p>
                    </div>
                  ) : (
                    <p className="text-base font-poppins text-greyscaleGON-900">
                      {currency((cart?.total_pembayaran - (!cart.biaya_pengiriman ? 0 : cart.biaya_pengiriman)))}
                    </p>
                  )}
                </div>
              </div>
              <Button
                Component={"a"}
                href={"/checkout"}
                formTarget="_blank"
                className="block w-full rounded-full bg-primaryGON-300 p-3 text-center text-sm font-medium text-white opacity-90 hover:opacity-100"
              >
                Proceed to Checkout
              </Button>
            </div>
          )}
        </SheetContent>
      </Sheet>
    </div>
  );
}
