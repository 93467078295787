"use client";

import { usePathname } from "next/navigation";
import React from "react";
import Search from "./Search";
import { Button } from "@/components/atoms/button";
import Link from "next/link";
import { SearchIcon } from "lucide-react";
import { usePageViewPixelFB } from "@/functions/pixelEvents";

export function CompoHeaderMiddle({
  dataBrands = []
}: {
  dataBrands: string[];
}) {
  usePageViewPixelFB();

  const pathname = usePathname();
  return (
    <div className="hidden @2xl:flex ">
      {pathname.includes("search") ? (
        <Search />
      ) : (
        dataBrands?.slice(0, 5).map((a, b) => {
          return (
            <Button
              Component={"a"}
              href={`/search/${a}`}
              className="text-greyscaleGON-400  ring-greyscaleGON-400 hover:bg-greyscaleGON-300 hover:text-greyscaleGON-500 focus-visible:bg-greyscaleGON-500 p-2 ring-1 rounded-full px-3 py-2"
              key={b}
              size="small"
              variant="outlined"
            >
              {a}
            </Button>
          );
        })
      )}
    </div>
  );
}

export function CompoBtnSearch() {
  const pathname = usePathname();

  return pathname.includes("search") ? null : (
    <Link
      href={"/search"}
      className="group hidden @2xl:flex justify-center items-center px-3 py-2 h-10 ring-1 ring-greyscaleGON-400 rounded-full"
    >
      <SearchIcon className="w-4 h-4 group-hover:scale-150 transition-all delay-75 duration-75 text-greyscaleGON-400 group-hover:text-primaryGON-300" />
    </Link>
  );
}
