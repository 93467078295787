import currency from "@/functions/currency";
import { useHitungDiskon } from "@/lib/utils";

export default function HargaItemCart({
  item,
  variantDetail
}: {
  item: variantKeyField;
  variantDetail: VariantDiskon;
}) {
  let checkUseDiskon = useHitungDiskon(
    variantDetail,
    variantDetail?.price_tags
  );

  return (
    <p className="text-sm font-poppins text-greyscaleGON-900">
      {currency(item?.jumlah * (checkUseDiskon || 0))}
    </p>
  );
}
